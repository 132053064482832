import * as React from 'react';
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Modal from "antd/lib/modal";
import Icon from '../Icon';
import * as intl from 'react-intl-universal';
const closeIcon = React.createElement(Icon, { icon: 'close' });
export default function AModal(props) {
    return React.createElement(Modal, Object.assign({ closeIcon: closeIcon }, props), props.children);
}
export function AModalConfirm(props) {
    const { options = { title: '', content: '', showMfaInput: false }, callbackes } = props;
    const [inputValue, setInputValue] = React.useState('');
    let icon = {};
    const type = options.type || 'info';
    if (options.icon) {
        icon = options.icon;
    }
    else {
        icon = React.createElement(Icon, { icon: 'prompt', className: `${type}-icon` });
    }
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };
    return Modal.confirm(Object.assign(Object.assign({ title: options.title, content: (React.createElement("div", null,
            options.content,
            options.showMfaInput && (React.createElement(Input, { style: { marginTop: 16 }, placeholder: intl.get('Global.MfaInputPlaceholder'), value: inputValue, onChange: handleInputChange })))), closeIcon: closeIcon, icon: icon, okType: type === 'error' ? 'danger' : 'primary', okButtonProps: { ghost: false, className: type === 'error' ? 'ant-btn-primary' : '' }, autoFocusButton: null }, options), { onOk() { callbackes.ok && callbackes.ok(); },
        onCancel() { callbackes.cancel && callbackes.cancel(); } }));
}
