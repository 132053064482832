import * as React from 'react';
import Form from "antd/lib/form";
import Fields from './Fields';
import i18n from '@/common/I18n';
const FormItem = Form.Item;
function FormBuilderField(props) {
    const { form, label, name, className = '', tooltip, extra, rules, type, custom, placeholder, fieldProps = {} } = props;
    const Field = Fields[type] || custom;
    const lang = i18n.getDefault();
    if (type === 'custom') {
        return React.createElement(Field, Object.assign({ lang: lang }, { form, name, placeholder }, fieldProps));
    }
    else if (type === 'formList') {
        return React.createElement(Field, Object.assign({}, props));
    }
    else
        return (React.createElement(FormItem, { label: label, className: `wireframe-field ${custom ? 'custom-field' : ''} ${className}`, tooltip: tooltip, extra: extra },
            React.createElement(FormItem, { name: name, noStyle: true, rules: rules },
                React.createElement(Field, Object.assign({ lang: lang }, { form, name, placeholder }, fieldProps)))));
}
export default FormBuilderField;
