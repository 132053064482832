var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b;
import * as React from 'react';
import * as intl from 'react-intl-universal';
import * as utils from '@/common/utils';
import Divider from "antd/lib/divider";
import PageHeader from "antd/lib/page-header";
import Core from './Core';
import Cache from './cache';
import Icon from '@/components/Icon';
import ModalHelper, { Dialog } from '@/common/components/ModalHelper';
import Loading from '../Loading';
const deferGroup = Symbol('deferGroup');
const getTitle = Symbol('pageHeader');
const loadingGroup = Symbol('loadingGroup');
export default class Page extends Core {
    constructor(props) {
        super(props);
        this[_a] = [];
        this[_b] = 0;
        this.currentRouter = [];
        this.defer = (v) => {
            this[deferGroup].push(v);
        };
        this.getFormValues = (form, fields) => __awaiter(this, void 0, void 0, function* () {
            return yield new Promise((resolve, reject) => {
                window.form = form;
                form.validateFields(fields, (err, values) => {
                    if (err)
                        return reject(err);
                    return resolve(values);
                });
            });
        });
        this.getPrefixPath = (type, path) => {
            if (type === 'add') {
                return `/v2${path}`;
            }
            else {
                return path.replace(/\/v2/g, '');
            }
        };
        this.loading = (func) => ((...args) => __awaiter(this, void 0, void 0, function* () {
            this[loadingGroup] += 1;
            this.setState({ isLoading: this[loadingGroup] > 0 });
            const [value, err] = yield utils.pcall(func, ...args);
            if (this.mounted) {
                this[loadingGroup] -= 1;
                this.setState({ isLoading: this[loadingGroup] > 0 });
            }
            if (err)
                throw err;
            return value;
        }));
        this.isLoading = () => !this.mounted || this.state.isLoading;
        this.withLoading = (children) => {
            const { isLoading = this[loadingGroup] > 0 } = this.state || {};
            if (typeof children === 'function')
                return children(isLoading);
            return React.createElement(Loading, { enabled: isLoading }, children);
        };
        this.renderConfirmModal = (option) => ModalHelper.show(Object.assign({ Dialog }, Object.assign(Object.assign({}, option), { config: Object.assign(Object.assign({}, option.config), { closeIcon: React.createElement(Icon, { icon: 'close' }) }) })));
        this[loadingGroup] = 0;
        const current = this.path || '';
        const previous = Cache.path || '';
        const { routerMap = {} } = this.props;
        const removePrefixCurrent = this.getPrefixPath('remove', current);
        const hasRelation = current.includes(previous) || previous.includes(current);
        this.currentRouter = routerMap[removePrefixCurrent] || [];
        if (current && previous && !hasRelation) {
            Cache.clear();
        }
        Cache.path = current;
        const { componentDidMount, componentWillUnmount, setState } = this;
        this.componentDidMount = (...args) => {
            this.mounted = true;
            if (componentDidMount)
                componentDidMount.call(this, ...args);
        };
        this.componentWillUnmount = (...args) => {
            if (componentWillUnmount)
                componentWillUnmount.call(this, ...args);
            this.mounted = false;
            for (const defer of this[deferGroup].reverse()) {
                if (defer)
                    utils.pcall(defer).catch(() => null);
            }
        };
        this.setState = (...args) => {
            if (!this.mounted)
                return;
            setState.call(this, ...args);
        };
    }
    get cache() {
        return this.path && Cache.get(this.path);
    }
    set cache(value) {
        this.path && Cache.set(this.path, value);
    }
    get path() {
        const { match } = this.props;
        return match ? match.path : null;
    }
    [(_a = deferGroup, _b = loadingGroup, getTitle)]() {
        const { title, subTitle, backPath, extra } = (this.pageHeader && this.pageHeader()) || {};
        const { history } = this.props;
        const currentRouter = this.currentRouter;
        const currentRouterLen = currentRouter.length;
        const currentPage = currentRouter[currentRouterLen - 1] || {};
        const pageTitle = title || intl.get(`Menu.${currentPage.name}`).d(currentPage.title);
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'page-header' },
                React.createElement(PageHeader, { ghost: false, backIcon: currentRouterLen > 3 ? React.createElement(Icon, { icon: 'dropdown', className: 'left-arrow' }) : false, onBack: () => backPath ? history.replace(this.getPrefixPath('add', backPath)) : history.goBack(), title: pageTitle, subTitle: subTitle || '', extra: extra })),
            React.createElement(Divider, { className: 'page-header-divider' })));
    }
    render() {
        return this.superRender ? React.createElement(React.Fragment, null,
            this[getTitle](),
            this.superRender()) : this.props.children;
    }
}
Page.Loading = Loading;
Page.scrollToTop = () => {
    window.scroll(0, 0);
};
